<template>
    <div class="clinical xi">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>临床技术操作规范</h3>
                <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>临床技术操作规范</a></p>
            </div>
        </div>
        <div class="content-wrap">
            <div class="content">
                <div class="content-left">
                   <div class="left-nav">
                            <el-menu class="el-menu-vertical-demo"
                                 :key="sideNav.index"
                                 v-for="sideNav in clinicalCategory"
                                 :default-active="defaultActive + '3'">
                            <!--一级-->
                            <template>
                                <el-submenu :index="sideNav.index + '1'">
                                    <template slot="title">
                                        <span>{{sideNav.categoryName}}</span>
                                    </template>
                                    <!--二级-->
                                    <div class="subtitle">
                                        <template v-for="(subItem) in sideNav.children" >
                                            <el-submenu :index="subItem.categoryId + '2'">
                                                <template slot="title">
                                                    {{ subItem.categoryName }}
                                                </template>
                                                <!--三级-->
                                                      <el-menu-item v-for="(subItem2, i) in subItem.clinicalPractices"
                                                                  :key="i"
                                                                  :index="subItem2.clinicalPracticeId + '3'"
                                                                  @click="Detail(subItem2.clinicalPracticeId)">
                                                        <template slot="title">
                                                            {{ subItem2.clinicalName }}
                                                        </template>
                                                    </el-menu-item>
                                            </el-submenu>
                                        </template>
                                    </div>
                                </el-submenu>
                            </template>
                        </el-menu>
                    </div>
                </div>
                <div class="ny_container">
                    <h2 class="ny-right-tit" v-if="clinicalDetail">{{clinicalDetail.clinicalName}}</h2>
                    <ul class="ny-list" v-if="clinicalDetail">
                        <li class="ny-item" v-for="(info,index) in clinicalDetail.infos" :key="index">
                            <h3 v-if="info.title"><span></span>{{info.title}}</h3>
                            <div class="ny-mes">
                                <p v-if="info.info" v-html="info.info"></p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import hdCom from '../../components/header'
    import ftCom from '../../components/footer'
    // import rtBar from "../../components/right";
    import {ClinicalPractice} from "../../components/ClinicalPractice/ClinicalPractice";
    export default {
        name: "ClinicalPractice",
        components: {
            hdCom,
            ftCom,
            // rtBar
        },
        data(){
            var clinical = new ClinicalPractice(this.TokenClient, this.Services.Statics)
            return{
                ClinicalDomain: clinical,
                clinicalCategory:{},
                clinicalDetail: {}, //初始化详情
                defaultActive: 0,
            }
        },
        methods: {
            Init: function () {
                var _this = this;
                _this.rloading = this.openLoading()
                _this.ClinicalDomain.Clinical(function (data) {
                    _this.clinicalCategory = (data.data);
                    if( _this.clinicalCategory.length>0)
                    {
                        _this.defaultActive=_this.clinicalCategory[0].children[0].clinicalPractices[0].clinicalPracticeId;
                    }
                    _this.rloading.close();
                    _this.Detail(_this.defaultActive);
                },
                function (error) {
                    console.log('临床技术操作规范请求异常!请刷新重试。', error)
                })
            },
            //详情获取
            Detail: function (healthId) {
                let params = `${healthId}`
                var _this = this;
                _this.ClinicalDomain.Detail(params, function (data) {
                    _this.clinicalDetail = (data.data)
                },
                function (error) {
                    console.log('临床技术操作规范详情请求异常!请刷新重试。', error)
                })
            },
        },
        mounted() {
            this.Init();
        },
        destroyed() {
        },
    }
</script>

<style scoped>
    .banner {
        height: 116px;
        background: url("../../assets/img/jbk_banner.png")center no-repeat;
    }

    .banner-text {
        width: 1200px;
        margin: 0 auto;
    }

    .banner-text h3 {
        padding-top: 25px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 18px;
        color: #ffffff;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }

    .content-wrap {
        background: #fcfcfc;
    }

    .content {
        position: relative;
        width: 1440px;
        background: none;
        margin: 0 auto;
        padding: 30px 0;
    }
    .content-left{
        float: left;
        width: 320px;
        padding: 20px;
        background: #ffffff;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
    }
    .left-nav-wrap {
        position: absolute;
        width: 260px;
        max-height: 520px;
        box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
    }

    .left-nav {
        width: 100%;
        background: #FFFFFF;
        box-sizing: border-box;
    }

    .ny_container {
        margin-left: 280px;
        min-height: 690px;
    }

    .ny_container {
        padding: 10px 0;
        margin-left: 380px;
        min-height: 690px;
        background: #FFFFFF;
        box-shadow: 0px 5px 5px rgba(224, 224, 224, 0.3);
    }

    .ny-right-tit {
        text-align: center;
        margin: 10px 0;
        font-weight: normal;
        font-size: 24px;
    }

    .ny-right-info {
        padding: 0 30px;
        color: #454545;
        font-size: 15px;
        line-height: 30px;
    }

    .ny-list .ny-item h3 {
        font-size: 18px;
        padding: 15px 0;
    }

    .ny-list .ny-item h3 span {
        display: inline-block;
        width: 20px;
        height: 8px;
        margin-right: 20px;
        background: #1fb7a6;
    }

    .ny-list .ny-item .ny-mes {
        padding: 0 40px;
    }

    .ny-list .ny-item .ny-mes p {
        color: #666666;
        font-size: 14px;
        line-height: 30px;
    }

    .ny-item-child h4 {
        list-style: circle;
        font-weight: 700;
        color: #333333;
        margin: 10px 0;
    }

    .ny-list .ny-item h4 span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 50%;
        background: #1fb7a6;
    }
</style>
<style>
    .clinical .bar-list .yysc{
        display: none !important;
    }
    .xi .el-submenu__title {
        padding: 0 !important;
        background: #1fb7a6;
        color: #FFFFFF;
        margin-top: 7px;
    }
    .zhong .el-submenu__title {
        padding: 0 !important;
        background: #b76c12;
        color: #FFFFFF;
        margin-top: 7px;
    }
    .xi .subtitle .el-submenu__title:hover{
        background: rgba(31, 183, 166, 0.2);
    }
    .xi .el-menu-item.is-active,.xi .el-menu-item:hover{
        color: #01C2AC;
        background: rgba(31, 183, 166, 0.2);
    }
    .zhong .el-menu-item.is-active,.zhong .el-menu-item:hover{
        color: #b76c12;
        background: rgba(183, 108, 18, 0.2);
    }
    .el-image__inner {
        height: 150px;
        width: auto;
    }

    .clinical .el-radio.is-bordered {
        border-radius: 2px;
        width: 156px;
        text-align: center;
    }

    .clinical .el-radio.is-bordered + .el-radio.is-bordered {
        margin-left: 5px;
    }

    .clinical .el-radio {
        margin-right: 0;
    }

    .clinical .el-checkbox-group {
        display: inline;
    }

    .clinical .el-checkbox {
        width: 33.3%;
        margin: 10px 0;
    }
    /*.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #01c2ac;
      border-color: #01c2ac;
    }
    .el-checkbox__input.is-focus .el-checkbox__inner{
      border-color: #01c2ac;
    }
    .el-checkbox__inner:hover{
      border-color: #01c2ac;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #01c2ac ;
    }*/
    .clinical .el-checkbox__inner {
        width: 16px;
        height: 16px;
    }

    .clinical .el-checkbox__inner::after {
        height: 9px;
        left: 5px;
        width: 4px;
    }

    .clinical .el-badge__content.is-dot {
        height: 6px;
        width: 6px;
    }

    .clinical .el-badge__content.is-fixed.is-dot {
        right: 0px;
        top: 11px;
    }

    .clinical .el-submenu__title * {
        margin-left: 10px;
    }

    .el-menu {
        border-right: none;
    }
    .el-submenu__title i {
        color: #ffffff;
    }

    .el-menu-item, .el-submenu__title {
        height: 48px;
        line-height: 48px;
    }

    .subtitle {
        border: 1px solid #f2f2f2;
    }

    .subtitle .el-submenu__title i {
        color: #333333;
    }

    .subtitle .el-submenu__title {
        padding-left: 30px !important;
        background: #ffffff;
        color: #333333;
        margin-top: 0;
        height: 36px;
        line-height: 36px;
    }

    .subtitle .el-submenu__title:hover {
        color: #1fb7a6;
    }

    .el-submenu .el-menu-item {
        padding-left: 50px !important;
        height: 36px;
        line-height: 36px;
    }

    .el-menu-item:focus, .el-menu-item:hover {
        background-color: #ffffff;
    }

    .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
        border-radius: 50%;
    }

    .el-pagination {
        font-weight: 500;
    }
    .xi .el-radio.is-bordered.is-checked{
        border-color: #01C2AC;
    }
    .xi .el-radio__input.is-checked .el-radio__inner {
        border-color: #01C2AC;
        background: #01C2AC;
    }
    .xi .el-radio__input.is-checked+.el-radio__label{
        color: #01C2AC;
    }
    .zhong .el-radio.is-bordered.is-checked{
        border-color: #b76c12;
    }
    .zhong .el-radio__input.is-checked .el-radio__inner {
        border-color: #b76c12;
        background: #b76c12;
    }
    .zhong .el-radio__input.is-checked+.el-radio__label{
        color: #b76c12;
    }
</style>
